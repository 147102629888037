<template>
    <div class="my-0 py-0">
        <v-card :loading="loading" height="100vh">
            <v-card-title>
                <v-btn
                    small
                    icon
                    @click="close"
                    color="primary"
                    class="ml-n3 mr-2"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h2 class="font-weight-regular">
                    {{ process }}
                    <span class="font-weight-black"
                        >{{ bom.code }} | {{ bom.project.reference }}
                        {{ bom.project.name }}</span
                    >
                </h2>
                <v-spacer />
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text>
                <v-container fluid ref="container" class="px-0 mt-1 pb-0 mb-0">
                    <v-row no-gutters ref="details">
                        <v-col cols="4" class="pl-0 pt-2">
                            <div>
                                <p class="text-capitalize">
                                    <strong>STATUS: </strong>
                                    {{ ` ${bom.status}` }}
                                </p>
                                <p class="text-capitalize">
                                    <strong>CREATED BY: </strong>
                                    {{
                                        ` ${
                                            bom.creator && bom.creator.name
                                                ? bom.creator.name
                                                : bom.createdBy
                                        }`
                                    }}
                                </p>
                                <p class="text-capitalize">
                                    <strong>PROJECT MANAGER: </strong>
                                    {{
                                        bom.projectManager &&
                                        bom.projectManager.name
                                            ? bom.projectManager.name
                                            : bom.projectManager
                                    }}
                                </p>
                            </div>
                        </v-col>
                        <v-col cols="4" class="pl-0 pt-2">
                            <div>
                                <p>
                                    <strong>CREATED: </strong
                                    >{{
                                        ` ${formatDate(
                                            bom.createdOn.seconds ||
                                                bom.createdOn._seconds
                                        )}`
                                    }}
                                </p>
                                <v-textarea
                                    v-model="bom.notes"
                                    prepend-icon="mdi-text"
                                    prefix="Notes: "
                                    hide-details
                                    rows="1"
                                    class="pt-0"
                                    :disabled="true"
                                >
                                </v-textarea>
                            </div>
                        </v-col>
                        <v-spacer />
                        <v-col cols="4" class="d-flex justify-end mb-3">
                            <canvas id="BOMqr"></canvas>
                        </v-col>
                    </v-row>
                </v-container>
                <BOMItems
                    :bom="bom"
                    :itemsHeight="itemsHeight"
                    :readBOMOnly="readBOMOnly"
                    :partialDelivery="false"
                    @closeProcessBOM="close"
                    @updateBOM="updateBOM"
                />
            </v-card-text>
            <v-card-actions
                class="mx-3 mt-0 pt-0"
                v-if="bom.status == 'approved' && enableOptions"
            >
                <v-spacer />
                <v-btn
                    v-if="validateQuantity && sendBOMPermission"
                    rounded
                    color="primary"
                    class="white--text"
                    @click="openPartials"
                >
                    SEND TO DELIVER
                </v-btn>
            </v-card-actions>
            <div
                v-if="$router.currentRoute.name == 'deliver'"
                style="height: 47px !important;"
            ></div>
        </v-card>
        <!--Error Alert-->
        <Errors />
        <!--SEND FOR DELIVERY-->
        <v-dialog
            max-width="800px"
            :retain-focus="false"
            persistent
            v-model="partialsForm"
        >
            <SendPartials
                v-if="partialsForm"
                :bom="bom"
                @partialsAlert="partialsAlert"
                @replaceItems="replaceItems"
                @close="closePartials"
            />
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import qrious from 'qrious'
import API from '@/services/api'

export default {
    name: 'ProcessBOM',
    props: {
        bom: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        process: {
            type: String,
            required: true,
            default: '',
        },
        readBOMOnly: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        BOMItems: () => import('@/components/WorkOrders/BOMItems'),
        Errors: () => import('@/components/Layout/Errors'),
        SendPartials: () => import('@/components/WorkOrders/SendPartials.vue'),
    },
    data: () => ({
        loading: false,
        tab: null,
        headers: [],
        items: [],
        height: 0,
        itemsHeight: 0,
        activateAlert: false,
        alertMessage: '',
        sendBOMPermission: false,
        enableOptions: true,
        validRejectForm: false,
        rejectionTable: false,
        rejectBomForm: false,
        rejectedNotes: undefined,
        prevNotes: null,
        partialsForm: false,
    }),
    computed: {
        validateQuantity() {
            let conditions = [
                item => {
                    return item.quantity > 0
                },
            ]
            const response = this.bom.items.filter(item => {
                return conditions.every(condition => {
                    return condition(item)
                })
            })
            return response.length > 0
        },
    },
    async mounted() {
        try {
            this.prevNotes = this.bom.notes
            // qr code
            new qrious({
                element: document.getElementById('BOMqr'),
                value: `https://es-metals-project-agenda.web.app/work-orders/main-work-orders?bomId=${this.bom.id}`,
                size: 150,
            })
            // retrieve user
            const {
                data: { user },
            } = await API.getMyInfo()
            if (user.permissions.includes('sendBOM')) {
                this.sendBOMPermission = true
            }
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('close', this.bom)
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                details: { clientHeight: detailsHeight },
            } = this.$refs
            this.height =
                window.innerHeight - containerHeight - detailsHeight + 50
            this.itemsHeight = window.innerHeight - 345
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        updateBOM(updatedBOM) {
            this.bom = updatedBOM
        },
        replaceItems(partials) {
            try {
                this.loading = true
                for (const partial of partials.requests) {
                    const index = this.bom.items.findIndex(
                        item => item.id == partial.id
                    )
                    if (index >= 0) {
                        this.bom.items[index].remaining = partial.remaining
                        this.bom.items[index].requests =
                            (this.bom.items[index].requests
                                ? this.bom.items[index].requests
                                : 0) + partial.quantity
                    }
                }
                this.bom.deliver = true
                this.bom.items = [...this.bom.items]
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closePartials() {
            this.partialsForm = false
        },
        openPartials() {
            this.partialsForm = true
        },
        partialsAlert() {
            this.activateAlert = true
            this.alertMessage =
                'Partial quantities have been recorded correctly.'
        },
        sendToDeliver() {
            this.$emit('sendToDeliver')
        },
        sendBOM() {
            this.$emit('sendBOM')
        },
    },
}
</script>

<style></style>
